
  .card-title{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dedede;
    padding-inline: 20px;
  }
  .card-content{
    display: flex;
    flex-wrap: wrap;
   justify-content: flex-start;
   padding-inline: 20px;
   margin-top: 10px;
  }
  .card-footer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-inline: 20px;
   margin-bottom: 10px;
  }
  .card{
    margin-top: 20px;
   background-color: #fff;
   display: flex;
   flex-wrap: wrap;
   flex-direction: column;
   border-radius: 8px;
  }
  .card-button{
    background-color: transparent;
    border: 1px solid #dedede;
   color: #000 !important;
   border-radius: 6px;
   padding-inline: 10px;
   cursor: pointer;
   padding-block: 5px;
   margin-block: 5px;
   }

  @media (max-width: 600px) {

}