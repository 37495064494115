.chat-bubble {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
}

.chat-toggle {
  color: #fff;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-window {
  position: absolute;
  bottom: 50px;
  right: 25px;
  width: 350px;
  height: 500px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  /* padding: 10px; */
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Existing styles... */
.chat-header{
  height: 50px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-inline: 15px;
  padding-block: 30px;
}
.message-list {
height: calc(100% - 100px);
overflow-y: auto;
padding: 10px;
display: flex;
flex-grow: 1;
flex-direction: column;
}

.message {
margin-bottom: 10px;
padding: 5px 10px;
border-radius: 8px;
max-width: 70%;
word-wrap: break-word;
white-space: pre-line;
margin-top: 5px;
}

.message.user {
background-color: #b8b8b8c6;
align-self: flex-end;
}

.message.bot {
background-color: #f0f0f0;
align-self: flex-start;
}
textarea:focus, input:focus{
  outline: none;
}

.input-area {
display: flex;
align-items: center;
border-top: 1px solid #ddd;
padding: 10px;
border-bottom-left-radius: 8px;
border-bottom-right-radius: 8px;
}

.input-area input {
flex-grow: 1;
border: none;
background-color: transparent;
/* border: 1px solid #ccc; */
/* border-radius: 15px; */
padding: 5px 10px;
margin-right: 10px;
}

.input-area button {
background-color: #007bff;
color: white;
border: none;
border-radius: 15px;
padding: 5px 10px;
cursor: pointer;
}

/* Existing styles... */

.loading-bubbles {
display: flex;
justify-content: center;
align-items: center;
}

.bubble {
width: 8px;
height: 8px;
border-radius: 50%;
margin: 0 3px;
animation: bounce 0.6s infinite alternate;
}

@keyframes bounce {
from {
  transform: translateY(0);
}
to {
  transform: translateY(-15px);
}
}
