@import 'themeStyle.css';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  text-align: center;
}

.logo {
  width: 30px;
  height: 30px;
  display: flex;
}

.desktop-menu {
  display: block;
}

.mobile-menu {
  display: none;
}

@media only screen and (max-width: 768px) {
  .desktop-menu {
    display: none;
  }
  .mobile-menu {
    display: block;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}