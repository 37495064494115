@media (max-width: 600px) {
 .mobile-view{
    display: flex !important;
    flex-wrap: wrap !important;
 }
 .chart1{
    width: 100% !important;
    margin-bottom: 10px !important;
 }
 .chart2{
    width: 100% !important;
 }
}