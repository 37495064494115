.button,
.ant-btn.button {
  font-family: Arial, sans-serif;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid #d67632;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  background-color: #5f2d78;
  color: white;
}

.ant-popover-buttons {
    display: flex;
    justify-content: flex-end;
    background-color: #5f2d78;
    color: white
  }
  
  .ant-popover-button {
    margin: 8px;
  }
  