@media only screen and (max-width: 767px) {
    .mobile-view{
       display: flex !important;
       flex-wrap: wrap !important;
    }
    .card{
        width: 100% !important;
        margin-bottom: 8px !important;
    }
   }

   @media only screen and (min-width: 768px) and (max-width: 1024px) { /* tablet breakpoint */
    .mobile-view{
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: space-around !important;
     }
     .card{
         width: 45% !important;
         margin-bottom: 8px !important;
     }
    }

   .card{
    margin-top: 0px; 
    margin-bottom: 5px;
}