.scoping-form {
    min-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .form-row {
    margin-bottom: 16px;
  }
  
  .item-label {
    font-weight: 500;
    width: 580px;
  }
  
  .radio-group {
    display: flex;
    justify-content: space-evenly;
  }
  
  @media (max-width: 576px) {
    .radio-group {
      flex-direction: column;
    }
    .item-label,
    .radio-group {
      text-align: center;
    }
  }
  
  .submit-button {
    width: 100%;
    height: 40px;
  }
  