
.amplify-button--primary {
    background-color: #00152a;
}
.page-background{
    background-image: url('/src/assets/gen/space.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-blend-mode: multiply;
    background-color: #00152ab8;
}
.preAuditLogo-on-registerPage{
    max-height: 50px;
    margin-bottom: 20px;
   }
.amplify-tabs-item[data-state=active] {
 color: #fff;
 border-color: #00152a;
 background-color: #00152a;
}
.amplify-tabs-item[data-state=active]:hover {
    color: #fff;
   }

.amplify-tabs-item:hover {
    color: #00152a;
   }

[data-amplify-authenticator] [data-amplify-router] {
 background-color: #ffffff;
 border-color: #00152a;
}
.amplify-tabs-item[data-spacing=equal]{
  
}
.amplify-button--link {
    color: #00152a;
}
.amplify-button--link:hover {
    color: #00152a;
    background-color: #fff;
}

.amplify-input, .amplify-input:focus {
border-color: #00152a;
border: 1px solid;
}
.fieldContainer{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
select, .amplify-field-group__outer-end .amplify-field-group__control, .amplify-field-group__outer-start .amplify-select__wrapper:not(:first-child) .amplify-select:not(:first-child), .amplify-field-group__outer-start--quiet .amplify-field-group__control, .amplify-field-group__outer-start .amplify-field-group__control:not(:first-child), .amplify-field-group :not(:first-child) .amplify-input {
border-color: #00152a;
}
.amplify-checkbox__icon {
  background-color: #00152a;
  border-color: #00152a;
}
.input-select-field{
    border-color: #00152a;
}
